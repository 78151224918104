

.App {
    height: 100vh;
  }
  
  .loader-img {
    display: flex;
    align-items: center; 
    justify-content: center; 
    height: 100%; 
  }