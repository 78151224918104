.about_us_whole_page_div {
    background-image: url(https://demo.casethemes.net/troma/wp-content/uploads/2019/04/h5-bg-about1.jpg?id=771);
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    padding-top: 50px;

}

.about_us_first_main_div {
    display: flex;
    justify-content: space-evenly;
    gap: 30px;
    padding: 10px;
    /* opacity: 0; */
    /* animation: slideInFromBottom 1s ease-out forwards; */
}

@keyframes slideInFromBottom {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}



.about_us_same_div_one_11 {
    opacity: 0;
    border: 1px solid #ddd;
    width: 100%;
    padding: 40px 20px;
    border-radius: 3px;
    background: white;
    cursor: pointer;
}
.about_us_same_div_one_22 {
    opacity: 0;
    border: 1px solid #ddd;
    width: 100%;
    padding: 40px 20px;
    border-radius: 3px;
    background: white;
    cursor: pointer;
}
.about_us_same_div_one_33 {
    opacity: 0;
    border: 1px solid #ddd;
    width: 100%;
    padding: 40px 20px;
    border-radius: 3px;
    background: white;
    cursor: pointer;
}




.about_us_second_main_div {
    display: flex;
    justify-content: space-evenly;
    padding-top: 115px !important;
    padding-bottom: 250px;
}

.about_us_same_heading {
    font-size: 24px;
    font-weight: 600;
    color: #1d274e;
    clear: both;
    line-height: 1.25;
    margin-bottom: 22px;
    font-family: 'Poppins', sans-serif;
}

.about_us_same_div_p {
    font-size: 15px;
    line-height: 28px;
    color: #0f192d;
    font-family: 'Rubik', sans-serif;

}

.about_us_second_main_div img {
    opacity: 0;
    /* width: 100%; */
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }

}

.about_us_second_main_div_heading {
    font-size: 35px;
    line-height: 55px;
    color: #1d274e;
    margin: 0;
    margin-bottom: 16px;
    letter-spacing: -0.03em;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}

.about_us_second_main_div p {
    font-size: 15px;
    line-height: 28px;
    color: #0f192d;
    font-family: 'Rubik', sans-serif;
}


.leading_development_div {
    width: 50%;
    margin: 0px 30px 0px 30px;
}



.about_us_same_div_one_11:hover {
    border: 1px solid white;
    border-bottom: 3px solid #ff5a3c;
    border-radius: 10px;
    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.06);
}
.about_us_same_div_one_22:hover {
    border: 1px solid white;
    border-bottom: 3px solid #ff5a3c;
    border-radius: 10px;
    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.06);
    
}
.about_us_same_div_one_33:hover {
    border: 1px solid white;
    border-bottom: 3px solid #ff5a3c;
    border-radius: 10px;
    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.06);
}


.big_img_div{
    width: 50%;
    margin-left: 22px;
    text-align: end;
}





@media (max-width: 1400px) {
    /* .about_us_whole_page_div{
        margin-top: 120px;
    } */
    .about_us_second_main_div img{
        width: 100%;
    }
}


@media (max-width: 1000px) {
    .about_us_second_main_div {
        flex-wrap: wrap;
        gap: 40px;
    }

    .leading_development_div {
        width: 100%;
    }
    .big_img_div{
        width: auto;
        margin-left: 0;
    }
}


@media (max-width: 900px) {
    .about_us_whole_page_div {
        padding-top: 150px;
        margin-top: 150px;
    }
    .about_us_first_main_div{
        flex-wrap: wrap;
    }

}

@media (max-width: 800px) {
    .about_us_second_main_div img {
        width: 400px;
    }
    .about_us_second_main_div_heading{
        font-size: 30px;
        line-height: 45px;
    }
}

@media (max-width: 400px) {
    .about_us_second_main_div img {
        width: 250px;
    }

    .about_us_same_div_one {
        width: 100%;
    }
    
}






