.contact_us_whole_page_div {
    display: flex;
}


.contact_us_first_main_div {
    width: 50%;
    padding: 89px 30px;
}



.contact_us_heading_div h3 {
    margin-bottom: 30px;
    font-size: 35px;
    line-height: 55px;
    font-weight: 700;
    letter-spacing: -0.03em;
    color: #1d274e;
    font-family: 'Poppins', sans-serif;
}


.contact_us_heading_div cite {
    color: #ff5a3c;
    font-style: normal;
}

.contact_us_office_address_text {
    font-size: 18px;
    color: #1d274e;
}

.contact_us_delaronde_text {
    color: #0f192d;
    font-size: 16px;
    line-height: 28px;
    font-family: 'Rubik', sans-serif;
}

.say_hello_div {
    color: #0f192d;
    padding-top: 35px !important;
    
}

.contact_us_first_main_div span {
    /* font-family: 'Poppins', sans-serif; */
    font-weight: 500;
}



.contact_us_second_main_div {
    width: 50%;
    background-image: url(http://demo.casethemes.net/troma/wp-content/uploads/2019/04/h5-bg-contact.png?id=881);
    background-color: #ff5a3c;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.contact_us_second_main_div form{
    display: grid;
    gap: 30px;
    padding: 109px 30px;
}

.input_same {
    width: 100%;
    border: none;
    border-bottom: 1px solid #fff;
    height: 42px;
    color: #fff;
    outline: none;
    background: transparent;
    font-size: 18px;
    font-weight: 600;
}

.contact_us_second_main_div textarea {
    width: 100%;
    height: 125px;
    border: none;
    font-size: 18px;
    border-bottom: 1px solid #fff;
    outline: none;
    background: transparent;
    color: #fff;
    font-weight: 600;
}

.contact_us_second_main_div ::placeholder {
    color: #fff;
}

.submit_btn_contact_us {
    border-radius: 45px;
    margin-top: 12px;
    padding-left: 32px;
    padding-right: 32px;
    line-height: 45px;
    background-color: #fff;
    color: #ff5a3c;
    text-transform: capitalize;
    border: none;
    cursor: pointer;
    font-weight: 600;
}





@media (max-width: 800px) {
    .contact_us_whole_page_div{
        flex-wrap: wrap;
    }
    .contact_us_first_main_div{
        width: 100%;
    }
    .contact_us_second_main_div{
        width: 100%;
        display: grid;
        gap: 40px;
    }
}


