.services_whole_page_div {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 100px;
    padding-top: 77px !important;
    padding-bottom: 120px !important;
    background-image: url(https://demo.casethemes.net/troma/wp-content/uploads/2019/04/h5-bg-services.png?id=823);
    background-color: #FFF6F4;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
    overflow: hidden;
}


.services_first_main_div {
    width: 60%;
    margin: auto;
}


.services_second_and_third_main_div {
    display: flex;
    justify-content: space-evenly;
    gap: 30px;
    /* opacity: 0; */
    /* animation: slideInFromRight 1s ease-out forwards; */
}



@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.our_services_text {
    margin-bottom: -3px;
    font-size: 35px;
    line-height: 55px;
    font-weight: 700;
    color: #1d274e;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    letter-spacing: -0.03em;
}

.cras_mattis_text {
    color: #0f192d;
    font-size: 15px;
    line-height: 28px;
    text-align: center;
    padding-top: 19px;
    font-family: 'Rubik', sans-serif;
}


.services_same_img_logo {
    height: 100px;
    width: 100px;
    background-color: white;
    position: absolute;
}

.services_same_img_logo img {
    height: 99px;
    width: 99px;
    background: transparent;
    border: 1px solid #ddd;
    border-radius: 3px;

}


.services_same_div_2 {
    border: 1px solid #ddd;
    background: white;
    border-radius: 5px;
    padding: 60px 30px 30px 30px;
    margin-left: 50px;
    margin-top: 50px;
    cursor: pointer;

}


.hover_class {
    opacity: 0;
}

.hover_class:hover .services_same_img_logo img {

    filter: brightness(0) invert(1);
    border: none;
}

.hover_class:hover .services_same_img_logo {
    transition: background-color .5s ease-in-out;
    background-color: #ff5a3c;
    border-radius: 4px;
}

.hover_class:hover .services_same_div_2 {
    border: 1px solid white;
    border-radius: 5px;
    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.06);
}

.services_same_div_2 h3 {
    font-weight: 600;
    color: #1d274e;
    line-height: 1.25;
    margin: 0 0 15px;
    margin-bottom: 12px;
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
}

.paragraph {
    font-size: 15px;
    line-height: 28px;
    color: #0f192d;
    font-family: 'Rubik', sans-serif;
}




@media (max-width: 850px) {

    .services_first_main_div {
        width: 100%;
    }

    .services_second_and_third_main_div {
        flex-wrap: wrap;
        gap: 80px;
    }
}




