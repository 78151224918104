.s_d_whole_page_div {
    background-image: url(https://cloudifytech.net/wp-content/uploads/2019/04/bg-slider5.jpg);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
    overflow: hidden;
    padding-bottom: 100px;
}

.S_D_main_div {
    width: 800px;
    position: absolute;
    margin-top: 12%;
    margin-left: 25%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    opacity: 0;
    /* animation: slideInFromLeft 1s ease-out forwards; */
}


@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.S_D_main_div P {
    width: 500px;
    font-size: 16px;
    line-height: 1.625;
    color: #2a3045;
    -webkit-font-smoothing: antialiased;
    font-weight: normal;
    letter-spacing: 0;
}

.s_d_heading {
    font-size: 60px;
    font-weight: 700;
    color: #333;
}


.logo_img_cloudify {
    padding: 20px 0px 0px 70px;
    opacity: 0;
    margin-left: 20%;
    /* animation: slideInFromTop 1s ease-out forwards; */
}

@keyframes slideInFromTop {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }

}

.aabbcc {
    /* animation: slideInFromRight 1s ease-out forwards; */
    opacity: 0;
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.circle_lines_img {
    position: absolute;
    left: 48%;
    animation: slideInFromTop 1s ease-out forwards;
}



.third_fourth_img {
    display: flex;
    justify-content: end;
}


.s_d_triangle_img {
    position: absolute;
    left: 100px;
    top: 668px;
}






@media (max-width: 1900px) {
    .logo_img_cloudify {
        margin-left: 17%;
    }
    .S_D_main_div{
        margin-left: 22%;
    }
}
@media (max-width: 1700px) {
    .logo_img_cloudify {
        margin-left: 10%;
    }
    .S_D_main_div{
        margin-left: 13%;
        margin-top: 15%;
    }
}

@media (max-width: 1500px) {
    .logo_img_cloudify {
        margin-left: 0%;
    }
}

@media (max-width: 1400px) {
    .s_d_whole_page_div {
        padding-bottom: 200px;
    }
    .S_D_main_div{
        margin-left: 8%;
        margin-top: 17%;
    }
}



@media (max-width: 1350px) {
    .aabbcc {
        height: 550px;

    }

    .S_D_main_div {
        margin-top: 300px;
        width: 400px;
    }

    .s_d_triangle_img {
        display: none;
    }
}
@media (max-width: 1150px){
.aabbcc{
    height: 450px;
}
}
@media (max-width: 950px){
.aabbcc{
    height: 400px;
}
}

@media (max-width: 1000px) {
    .circle_lines_img {
        left: 40%;
    }
}

@media (max-width: 870px) {
    /* .s_d_whole_page_div{
        padding: 0;
    } */
    .aabbcc {
        height: 300px;

    }

    .circle_lines_img {
        left: 35%;
    }
}


@media (max-width: 700px) {
    .logo_img_cloudify {
        padding: 40px
    }

    .aabbcc {
        height: 200px;

    }

    .circle_lines_img {
        width: 50px;
        height: 50px;

    }

    .S_D_main_div {
        width: 300px;
    }

    .s_d_heading {
        font-size: 30px;
    }

    .S_D_main_div p {
        width: 300px;
    }
}


@media (max-width: 400px) {
    .logo_img_cloudify {
        padding: 30px;
    }

    .circle_lines_img {
        left: 25%;
    }

    .S_D_main_div {
        margin-top: 333px;
    }
}




.movable-div {

    transition: transform 0.25s ease;
    /* Smooth movement */
}